<template>
  <div class="content">
      <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 1243px;
  margin: 0 auto;
  margin-top: 170px;
  min-height: 900px;
}
</style>
